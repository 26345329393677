import {API} from 'constants/api';

import {AxiosPromise} from 'axios';
import {AddRemoveTagDTO} from 'typescript/dto/AddRemoveTagDTO';
import {CreateProjectDTO} from 'typescript/dto/createProjectDTO';
import {ExportProjectDto} from 'typescript/dto/ExportProjectDto';
import {IGetProjectsDTO} from 'typescript/dto/GetProjectsDTO';
import {MemberDTO} from 'typescript/dto/MemberDTO';
import {OrganizerDTO} from 'typescript/dto/OrganizerDTO';
import {SearchProjectsDTO} from 'typescript/dto/searchProjectsDTO';
import {ResponseWithFilters} from 'typescript/interfaces/ResponseWithFilters';
import {ActivityLog} from 'typescript/models/ActivityLog';
import {SingleProject} from 'typescript/models/SingleProject';
import httpClient from 'utils/httpClient/httpClient';

import {generateProjectQueries} from './Helpers/generateProjectQueries';
import {generateQueryObject} from './Helpers/generateQueryObject';

export function getProjects(dto?: IGetProjectsDTO): AxiosPromise<ResponseWithFilters<SingleProject[]>> {
  const query = generateQueryObject(dto, generateProjectQueries);
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}${query}`);
}

export function getSingleProject(id: number): AxiosPromise<SingleProject> {
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}/${id}`);
}

export function updateProject(id: number, dto: CreateProjectDTO): AxiosPromise<SingleProject> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${id}`, dto);
}

export function deleteProject(id: number): AxiosPromise<string> {
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${id}`);
}

export function undeleteProject(id: number): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${id}/undelete`);
}

export function undeleteProjectsBulk(ids: number[]): AxiosPromise<string> {
  return httpClient.post(`${API.PROJECT_SERVICE.BASE}/bulk/undelete`, {ids});
}

export function leaveProject(dto: OrganizerDTO): AxiosPromise<string> {
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${dto.id}/organizer/${dto.userId}/remove`);
}

export function archiveProject(id: number): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${id}/archive`);
}

export function unarchiveProject(id: number): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${id}/unarchive`);
}

export function unarchiveProjectsBulk(ids: number[]): AxiosPromise<string> {
  return httpClient.post(`${API.PROJECT_SERVICE.BASE}/bulk/unarchive`, {ids});
}

export function createProject(dto: CreateProjectDTO): AxiosPromise<SingleProject> {
  return httpClient.post(`${API.PROJECT_SERVICE.BASE}`, dto);
}

export function favoriteProject(id: number): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${id}/favorite/add`);
}

export function unfavoriteProject(id: number): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${id}/favorite/remove`);
}

export function searchProjects(dto: SearchProjectsDTO): AxiosPromise<ResponseWithFilters<SingleProject[]>> {
  const query = generateQueryObject(dto.filters, generateProjectQueries);

  return httpClient.get(`${API.SEARCH.PROJECTS}${query}&text=${dto.value}`);
}

export function addTag(dto: AddRemoveTagDTO): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${dto.id}/tag/${dto.tagId}/add`);
}

export function removeTag(dto: AddRemoveTagDTO): AxiosPromise<string> {
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${dto.id}/tag/${dto.tagId}/remove`);
}
export function addMember(dto: MemberDTO): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${dto.id}/member/${dto.userId}/add`);
}

export function removeMember(dto: MemberDTO): AxiosPromise<string> {
  return httpClient.delete(`${API.PROJECT_SERVICE.BASE}/${dto.id}/member/${dto.userId}/remove`);
}

export function addOrganizer(dto: OrganizerDTO): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${dto.id}/organizer/${dto.userId}/add`);
}

export function removeOrganizer(dto: OrganizerDTO): AxiosPromise<string> {
  return httpClient.put(`${API.PROJECT_SERVICE.BASE}/${dto.id}/leave`);
}

export function searchArchiveProjects(search: string): AxiosPromise<ResponseWithFilters<SingleProject[]>> {
  return httpClient.get(`${API.SEARCH.PROJECTS}?text=${search}&active=false`);
}

export function searchDeletedProjects(search: string): AxiosPromise<ResponseWithFilters<SingleProject[]>> {
  return httpClient.get(`${API.SEARCH.PROJECTS}?text=${search}&deleted=true`);
}

export function getActivityLogs(id: number): AxiosPromise<ResponseWithFilters<ActivityLog[]>> {
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}/${id}/log?order=DESC`);
}

export function exportProject(dto: ExportProjectDto): AxiosPromise<Blob> {
  return httpClient.get(`${API.PROJECT_SERVICE.BASE}/${dto.id}/report/download`, {
    params: {latin: dto.latin},
    responseType: 'blob',
  });
}
